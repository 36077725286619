@font-face {
  font-family: 'Minecraft';
  src: url('fonts/Minecraft.ttf') format('truetype');
  }
  
@font-face {
  font-family: 'Gamer';
  src: url('fonts/Gamer.ttf') format('truetype');
  }
  
@font-face {
  font-family: 'Pixeled';
  src: url('fonts/Pixeled.ttf') format('truetype');
  }
  
@font-face {
  font-family: 'Retro';
  src: url('fonts/Retro Gaming.ttf') format('truetype');
  }
  
@font-face {
  font-family: 'VCR';
  src: url('fonts/VCR_OSD_MONO_1.001.ttf') format('truetype');
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    margin-top: 15rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-family: 'Retro', Arial, sans-serif;
    transition: all 0.5s ease;
  }
  
  .contact-form input::placeholder,
  .contact-form textarea::placeholder {
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.5s ease;
  }
  
  .contact-form textarea {
    height: 150px;
    resize: vertical;
  }
  
  .contact-form button {
    background-color: #000000;
    color: #ffffff;
    font-family: 'Retro';
    font-size: 1.5rem;
    padding: 14px 20px;
    margin: 8px auto;
    border: none;
    cursor: pointer;
    width: 90%;
    transition: all 0.5s ease;
    border-radius: 10px;
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .contact-form button:hover {
    background-color: #ffffff;
    color: #000000;
    transform: scale(1.05);
  }
  
  .contact-form button:active {
    background-color: #3e8e41;
    transform: scale(0.95);
  }
  
  @keyframes buttonClick {
    0% { transform: scale(1); }
    50% { transform: scale(0.9); }
    100% { transform: scale(1); }
  }
  
  .contact-form button.clicked {
    animation: buttonClick 0.3s ease;
  }

  .contact-section.active h2 {
    text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff;
  }

.contact-section.active .contact-form {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
  border: 2px solid #00ffff;
}

.contact-section.active .contact-form input,
.contact-section.active .contact-form textarea {
  background: rgba(0, 255, 255, 0.1);
  color: #00ffff;
}

.contact-section.active .contact-form input::placeholder,
.contact-section.active .contact-form textarea::placeholder {
  color: rgba(0, 255, 255, 0.7);
}

.contact-section.active .contact-form button {
  background-color: #00ffff;
  color: #000000;
}

.contact-section.active .contact-form button:hover {
  background-color: #ffffff;
  box-shadow: 0 0 20px #00ffff;
}

.contact-section.active .contact-form button:active {
  background-color: #00cccc;
}

.contact-stars {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.contact-star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.contact-section.active .contact-stars .contact-star {
  opacity: 1;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  margin-top: 5rem;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-family: 'Retro', Arial, sans-serif;
  transition: all 0.5s ease;
  font-size: 0.8rem;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
  transition: color 0.5s ease;
}

.contact-form textarea {
  height: 120px;
  resize: vertical;
}

.contact-form button {
  background-color: #000000;
  color: #ffffff;
  font-family: 'Retro';
  font-size: 1.5rem;
  padding: 14px 20px;
  margin: 8px auto;
  border: none;
  cursor: pointer;
  width: 90%;
  transition: all 0.5s ease;
  border-radius: 10px;
  display: block;
  position: relative;
  overflow: hidden;
}

.contact-form button:hover {
  background-color: #ffffff;
  color: #000000;
  transform: scale(1.05);
}

.contact-form button:active {
  background-color: #3e8e41;
  transform: scale(0.95);
}

@keyframes buttonClick {
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
}

.contact-form button.clicked {
  animation: buttonClick 0.3s ease;
}

.contact-section.active h2 {
  text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff;
}

.contact-section.active .contact-form {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
  border: 2px solid #00ffff;
}

.contact-section.active .contact-form input,
.contact-section.active .contact-form textarea {
  background: rgba(0, 255, 255, 0.1);
  color: #00ffff;
}

.contact-section.active .contact-form input::placeholder,
.contact-section.active .contact-form textarea::placeholder {
  color: rgba(0, 255, 255, 0.7);
}

.contact-section.active .contact-form button {
  background-color: #00ffff;
  color: #000000;
}

.contact-section.active .contact-form button:hover {
  background-color: #ffffff;
  box-shadow: 0 0 20px #00ffff;
}

.contact-section.active .contact-form button:active {
  background-color: #00cccc;
}

.mission-control,
.launch-countdown {
  font-size: 0.8em;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}

.contact-section.active .mission-control,
.contact-section.active .launch-countdown {
  color: #00ffff;
  opacity: 1;
}

.contact-form button {
  position: relative;
  overflow: hidden;
}

.battery-fill {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  background-color: #00ff00;
  transition: width 0.5s ease;
  z-index: 1;
}

.button-text {
  position: relative;
  z-index: 2;
}
.satellite {
  width: 0.0000001vw;
  left: 100vw;
  top: -10vh;
  z-index: 100;

}
.jimmy-template {
  width: 40vw;
  height: auto;
  position: absolute;
  top: calc(182vh + (100vw - 320px) * 0.55);
  left: 10vw;
  z-index: 10;
  transform: translate(0, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: white;
}

.jimmy-template-image {
  width: 100%;
  height: auto;
}

.jimmy-info {

  padding: 4rem;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 30%;
  font-family: 'Retro';
  transform: translateY(-50%);
}

.jimmy-info h3 {
  font-size: 1.4em; /* Adjust this value as needed */
  margin-bottom: 0.5em;
}

.jimmy-info p {
  margin-bottom: 1.5em;
  font-size: 1em;
  white-space: pre-wrap;
  text-indent: -1em;
  padding-left: 1em;
}



.jimmy-info .label {
  font-weight: bold;
}

.jimmy-info .content {
  margin-left: 1em;
  display: block;
  text-indent: 1em;
}

.etai-template {
  width: 40vw;
  height: auto;
  position: absolute;
  top: calc(182vh + (100vw - 320px) * 0.55);
  left: 51vw; /* Position to the right of Jimmy */
  z-index: 10;
  transform: translate(0, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: white;
}

.etai-template-image {
  width: 100%;
  height: auto;
}

.etai-info {
  padding: 4rem;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 30%;
  font-family: 'Retro';
  transform: translateY(-50%);
}

.etai-info h3 {
  font-size: 1.4em;
  margin-bottom: 0.5em;
}

.etai-info p {
  margin-bottom: 1.5em;
  font-size: 1em;
  text-indent: -1em;
  padding-left: 1em;
}

.etai-info .label {
  font-weight: bold;
}

.etai-info .content {
  margin-left: 1em;
  display: block;
  text-indent: 1em;
}

/* General section styles */
.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  position: relative;
  z-index: 5; /* Bring sections to the front */
  font-family: 'Retro', Arial, sans-serif; /* Change the font family as needed */
  color: #d3d3d3; /* Default color */
  font-size: max(16px, 2vw); /* Base font size that grows as you zoom out */
}

/* Specific styles for each section if needed */
.games-section {
  color: #000000 !important; /* Example: Change color */
  font-size: max(18px, 2.5vw); /* Slightly larger font size that grows as you zoom out */
  font-weight: bold; /* Example: Change font weight */
  margin-top: -30px;
}
:root {
  /* Define CSS variables for responsive scaling */
  --base-font-size: 18px;
  --scale-factor: calc(1vw + 1vh) / 2; /* Average of viewport width and height */
  --responsive-font-size: calc(var(--base-font-size) + var(--scale-factor));
  --duck-size: calc(20vw + var(--scale-factor));
}

.about-section, .contact-section {
  color: #FFFFFF;
  font-size: max(18px, 2.5vw);
  font-weight: bold;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 80vw;
}

.about-section {
  top: calc(127vh + (100vw - 320px) * 0.55);
  z-index: 2;
}

.contact-section {
  top: calc(187vh + (100vw - 320px) * 0.97);
  z-index: 1;
}

.contact-section h2{
  margin-bottom: 1rem !important;
  transition: text-shadow 0.5s ease;
}
@media (max-width: 768px) {
  .about-section, .contact-section {
    width: 90%;
    font-size: max(16px, 4vw);
  }

  .about-section {
    top: calc(130vh + (100vw - 320px) * 0.55);
  }

  .contact-section {
    top: calc(190vh + (100vw - 320px) * 0.95);
  }
}


/* Additional styles for h2 and p tags within sections */
.section h2 {
  font-size: max(40px, 5vw); /* Larger heading size that grows as you zoom out */
  margin-bottom: 1rem;
}

.section p {
  font-size: max(1px, 1.4vw); /* Paragraph size that grows as you zoom out */
  text-align: center; /* Center text */
  max-width: 100%; /* Increased width for even wider text */
  width: 120%; /* Set a fixed width */
  margin: 0 auto; /* Center the paragraph */
}



.spaceship1,
.spaceship2,
.spaceship3 {
  position: absolute;
  top: 70%;
  left: -10vw; /* Start off-screen to the left */
  width: 5vw; /* Adjust the size as needed */
  transition: transform 0.2s linear; /* Smooth transition */
  opacity: 1;
}

.spaceship2 {
  top: 80%; /* Adjust the top position for the second spaceship */
}

.spaceship3 {
  top: 60%; /* Adjust the top position for the third spaceship */
}





.background1 {
  position: absolute;
  width: 100%;
  top: 158vh;
  left: 0;
  z-index: 0;
}


.line {
  position: absolute;
  background-color: #fff;
  height: 0.2vh;
  transform-origin: 0 0;
  transition: width 0.2s ease;
}

.duck-image {
  position: absolute;
  top: 155vh;
  right: 40%;
  width: 20vw;
  height: 20vw;
  z-index: 1;
}
.speechbubble {
  position: absolute;
  top: 155vh;
  right: 27%;
  width: 15vw;
  z-index: 1;
}



body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: black;
  color: #d3d3d3;
  font-family: 'Arial', sans-serif;
  transition: background-color 0.3s;
  overflow-x: hidden; /* Disallow horizontal scrolling */
}

.app {
  width: 100vw;
}

.sections {
  width: 100vw;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.word {
  font-size: 3vw;
  margin: 0 2vw;
  cursor: pointer;
  transition: color 0.3s;
  font-weight: 300;
  color: #949494;
  font-family: 'Retro';
}

.word:hover {
  color: #fff !important;
}

.sections {
  width: 100vw;
}

.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background: transparent;
}

.full-circle {
  position: absolute;
  bottom: -40vh;
  left: 50%;
  transform: translateX(-50%);
  width: 35vw;
  height: 35vw;
  border: 0.5vw solid white;
  border-radius: 50%;
  background: black;
  box-sizing: border-box;
  z-index: 1;
}

.star {
  position: absolute;
  width: 0.4vw;
  height: 0.4vw;
  background-color: #949494;
  border-radius: 50%;
}

@media (max-width: 1349px) {
  .jimmy-info h3 {
    font-size: 1.12em;
  }
  .jimmy-info p {
    font-size: 0.8em;
  }
  .etai-info h3 {
    font-size: 1.12em;
  }
  .etai-info p {
    font-size: 0.8em;
    margin-bottom: 2em;
  }

}


@media (max-width: 1178px) {
  .jimmy-info h3 {
    font-size: 1.05em;
  }
  .jimmy-info p {
    font-size: 0.7em;
  }
  .etai-info h3 {
    font-size: 1.05em;
  }
  .etai-info p {
    font-size: 0.7em;
  }
  .full-circle {
    width: 45vw;
    height: 45vw;
    bottom: -40vh;
  }
  .word {
    font-size: 3.5vw;
  }

}

@media (max-width: 1078px) {
  .jimmy-info h3 {
    font-size: 0.8em;
  }
  .jimmy-info p {
    font-size: 0.6em;
  }
  .etai-info h3 {
    font-size: 0.8em;
  }
  .etai-info p {
    font-size: 0.6em;
  }


}

@media (max-width: 924px) {
  .word {
    font-size: 4.5vw;
  }

  .full-circle {
    width: 52vw;
    height: 52vw;
    bottom: -35vh
  }
  .jimmy-info h3 {
    font-size: 0.75em;
  }
  .jimmy-info p {
    font-size: 0.65em;
    transform: scale(0.8);
    transform-origin:left;
    line-height: 1.3; /* Add this line to reduce the gap between lines */
    margin-bottom: 0.5em;
  }
  .jimmy-info {
    padding: 2.5rem;
    width: 65%;
  }
  .etai-info h3 {
    font-size: 0.75em;
  }
  .etai-info p {
    font-size: 0.65em;
    transform: scale(0.8);
    transform-origin:left;
    line-height: 1.3; /* Add this line to reduce the gap between lines */
    margin-bottom: 1.4em;
  }
  .etai-info {
    padding: 2.5rem;
    width: 65%;
  }

}

@media (max-width: 811px) {
  .jimmy-info h3 {
    font-size: 0.7em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.8);
    transform-origin:left;
    line-height: 1.3; /* Add this line to reduce the gap between lines */
    margin-bottom: 0.5em;
  }
  .jimmy-info {
    width: 65%;
    padding: 2rem;
  }
  .etai-info h3 {
    font-size: 0.7em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.8);
    transform-origin:left;
    line-height: 1.3; /* Add this line to reduce the gap between lines */
    margin-bottom: 1.5em;
  }
  .etai-info {
    width: 65%;
    padding: 2rem;
  }
  .spaceship1,
  .spaceship2,
.spaceship3 {
  width: 7vw;

}
}

@media (max-width: 750px) {
  .full-circle {
    width: 57vw;
    height: 57vw;
    bottom: -30vh;
  }
  .word {
    font-size: 4.5vw;
  }

  .about-section {
  top: calc(130vh + (100vw - 320px) * 0.52);
}


.jimmy-template, .etai-template {
  top: calc(186vh + (100vw - 320px) * 0.47);
}
}
.contact-form {
  margin-top: 2rem;
}

@media (max-width: 670px) {
  .jimmy-info h3 {
    font-size: 0.5em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.7);
    transform-origin:left;
    line-height: 1.0; /* Add this line to reduce the gap between lines */
    margin-bottom: 0.5em;
  }
  .jimmy-info {
    width: 70%;
    padding: 1.5rem;
  }
  .etai-info h3 {
    font-size: 0.5em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.7);
    transform-origin:left;
    line-height: 1.0; /* Add this line to reduce the gap between lines */
    margin-bottom: 1.3em;
  }
  .etai.info {
    width: 70%;
    padding: 1.5rem;
  }
  .spaceship1,
  .spaceship2,
.spaceship3 {
  width: 9vw;

}

}

@media (max-width: 584px) {
  .jimmy-info h3 {
    font-size: 0.4em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.6);
    transform-origin:left;
    line-height: 1.0; /* Add this line to reduce the gap between lines */
    margin-bottom: -1.2em;
  }
  .jimmy-info {
    width: 80%;
    padding: 1.5rem;
    top: 42%;
  }
  .etai-info h3 {
    font-size: 0.4em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.6);
    transform-origin:left;
    line-height: 1.0; /* Add this line to reduce the gap between lines */
    margin-bottom: -0.8em;
  }
  .etai-info {
    width: 80%;
    padding: 1.5rem;
    top: 42%;
  }

  .word {
    font-size: 5.5vw;
  }

  .full-circle {
    width: 70vw;
    height: 70vw;
    bottom: -32vh
  }

  .spaceship1,
  .spaceship2,
.spaceship3 {
  width: 10vw;


}
}

@media (max-width: 509px) {
  .jimmy-info h3 {
    font-size: 0.8em;
    transform: scale(0.5);
    transform-origin:left;
    margin-bottom: -1em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.5);
    transform-origin:left;
    line-height: 1.1; /* Add this line to reduce the gap between lines */
    margin-bottom: -1.4em;
  }
  .jimmy-info {
    width: 100%;
    padding: 1.2rem;
    top: 37%;
  }
  .etai-info h3 {
    font-size: 0.8em;
    transform: scale(0.5);
    transform-origin:left;
    margin-bottom: -0.7em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.5);
    transform-origin:left;
    line-height: 1.1; /* Add this line to reduce the gap between lines */
    margin-bottom: -1.1em;
  }
  .etai-info {
    width: 100%;
    padding: 1.2rem;
    top: 37%;
  }
  .button-text {
    font-size: 0.7em;
  }
  .mission-control{
    font-size: 0.8rem;
  }
  .contact-form{
    width: 50vw;
    padding: 15px;
    margin-top: 1rem;
  }
  .contact-form input,
.contact-form textarea {
  font-size: 0.4rem;
  padding: 5px;
}
.contact-form textarea {
  height: 50px;
}
.about-section {
  top: calc(135vh + (100vw - 320px) * 0.52);
}


.jimmy-template, .etai-template {
  top: calc(190vh + (100vw - 320px) * 0.47);
}
.contact-section {
  top: calc(188vh + (100vw - 320px) * 0.85);
}
.section{
  font-size: 0.8rem;
}
.section h2 {
  font-size: max(32px, 3vw); /* Larger heading size that grows as you zoom out */
  margin-bottom: 1rem;
}


  

}

@media (max-width: 430px) {
  .jimmy-info h3 {
    font-size: 0.8em;
    transform: scale(0.5);
    transform-origin:left;
    margin-bottom: -1em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.4);
    transform-origin:left;
    line-height: 1.2; /* Add this line to reduce the gap between lines */
    margin-bottom: -2em;
  }
  .jimmy-info {
    width: 120%;
    padding: 1rem;
    top: 39%;
  }
  .etai-info h3 {
    font-size: 0.8em;
    transform: scale(0.5);
    transform-origin:center;
    margin-bottom: -0.7em;
    text-indent: 1em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.4);
    transform-origin:center;
    line-height: 1.2; /* Add this line to reduce the gap between lines */
    margin-bottom: -2em;
  }
  .etai-info {
    width: 100%;
    padding-right: 0.2rem;
    top: 35%;
    left: -1%;
  }

  .full-circle {
    width: 90vw;
    height: 90vw;
    bottom: -28vh
  }

}

@media (max-width: 380px) {
  .jimmy-info h3, .etai-info h3{
    font-size: 0.7em;
  }
}
@media (max-width: 360px) {
  .etai-info {
    margin-top: 0.3em;
  }
}

@media (max-width: 339px) {
  .jimmy-info h3 {
    font-size: 0.8em;
    transform: scale(0.4);
    transform-origin:left;
    margin-bottom: -1.5em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.35);
    transform-origin:left;
    line-height: 1; /* Add this line to reduce the gap between lines */
    margin-bottom: -2.5em;
  }
  .jimmy-info {
    width: 140%;
    padding: 0.8rem;
    top: 30%;
  }
  .etai-info h3 {
    font-size: 0.8em;
    transform: scale(0.4);
    transform-origin:left;
    margin-bottom: -1.5em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.35);
    transform-origin:left;
    line-height: 1; /* Add this line to reduce the gap between lines */
    margin-bottom: -2.5em;
  }
  .etai-info {
    width: 140%;
    padding: 0.8rem;
    top: 30%;
  }
}

.astronaut {
  position: fixed;
  left: -150px;
  bottom: 40%;
  width: 150px;
  height: auto;
  z-index: 1000;
}

@media (max-width: 540px) and (min-width: 540px) and (max-height: 720px) and (min-height:720px){
  .jimmy-info h3 {
    font-size: 0.9em;
    transform: scale(0.7);
    transform-origin:left;
    margin-bottom: -1em;
  }
  .jimmy-info p {
    font-size: 0.6em;
    transform: scale(0.7);
    transform-origin:left;
    line-height: 1.5; /* Add this line to reduce the gap between lines */
    margin-bottom: -2em;
  }
  .jimmy-info {
    width: 90%;
    padding: 1rem;
    top: 39%;
    left: 32%
  }
  .etai-info h3 {
    font-size: 0.8em;
    transform: scale(0.7);
    transform-origin:center;
    margin-bottom: -0.7em;
    text-indent: 0em;
  }
  .etai-info p {
    font-size: 0.6em;
    transform: scale(0.7);
    transform-origin:center;
    line-height: 1.5; /* Add this line to reduce the gap between lines */
    margin-bottom: -2em;
  }
  .etai-info {
    width: 90%;
    padding-right: 0.2rem;
    top: 35%;
    left: 15%;
  }


}

/*More media queries for contact-us section */
@media (max-width: 375px) and (min-width: 375px) and (max-height: 667px) and (min-height: 667px)  {
  .contact-section {
    top: calc(210vh + (100vw - 320px) * 0.85);
  }

}
@media (max-width: 414px) and (min-width: 414px) and (max-height: 896px) and (min-height: 896px)  {
  .contact-section {
    top: calc(200vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 390px) and (min-width: 390px) and (max-height: 844px) and (min-height: 844px)  {
  .contact-section {
    top: calc(200vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 430px) and (min-width: 430px) and (max-height: 932px) and (min-height: 932px)  {
  .contact-section {
    top: calc(195vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 412px) and (min-width: 412px) and (max-height: 915px) and (min-height: 915px)  {
  .contact-section {
    top: calc(195vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 360px) and (min-width: 360px) and (max-height: 740px) and (min-height: 740px)  {
  .contact-section {
    top: calc(200vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 540px) and (min-width: 540px) and (max-height: 720px) and (min-height: 720px)  {
  .contact-section {
    top: calc(210vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 344px) and (min-width: 344px) and (max-height: 882px) and (min-height: 882px)  {
  .contact-section {
    top: calc(205vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 412px) and (min-width: 412px) and (max-height: 914px) and (min-height: 914px)  {
  .contact-section {
    top: calc(205vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 1024px) and (min-width: 1024px) and (max-height: 600px) and (min-height: 600px)  {
  .contact-section {
    top: calc(235vh + (100vw - 320px) * 0.85);
  }

}

@media (max-width: 1280px) and (min-width: 1280px) and (max-height: 800px) and (min-height: 800px)  {
  .contact-section {
    top: calc(235vh + (100vw - 320px) * 0.85);
  }

}



/* Remove any specific h2 styling for contact-section if it exists */
